import { Injectable, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import {
  Subject,
  of,
  ObservedValueOf,
  Observable,
  BehaviorSubject,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Logger } from '@utils/logger';

import {
  SiteSearchParams,
  SiteSearchConfig,
  SegmentId,
  ShareClassCode,
  GlobalId,
} from '@types';

import { SegmentService } from '@services/segment.service';
import { AppStateService } from '@services/app-state.service';
import { ProfileService } from '@services/profile.service';
import { GlobalConfigService } from '@services/global-config-service';

import { FilterItem } from '@search/interfaces/search.interface';
import {
  FT_IDENTITY_TOKEN_KEY,
  PersonalisationAPIService,
  StorageService,
} from '@services';

const logger = Logger.getLogger('SearchConfigService');
@Injectable({
  providedIn: 'root',
})
export class ConfigService implements OnDestroy {
  searchParameters: SiteSearchParams;
  searchConfig: SiteSearchConfig;
  currentSegment: string;
  insightAudiences: string;
  spaBaseUrl: string;
  private parentGlobalFirmId: GlobalId;

  locale = 'en-usa'; // default, overwritten by config
  private firmLocaleSearchConfig = {
    // UDS-1632 additional configuration for firm which requires different locale index
    16590073: 'primerica',
  };
  isLoggedIn = 'n';
  isLoggedIn$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.isLoggedIn
  );
  hasSearch = false;

  unsubscribe$: Subject<void> = new Subject<void>();

  knownExtensions = ['pdf', 'xls', 'doc', 'ppt', 'csv', 'pptx', 'docx', 'xlsx'];

  constructor(
    private segmentService: SegmentService,
    private profileService: ProfileService,
    private pageConfigService: GlobalConfigService,
    private appStateService: AppStateService,
    private storageService: StorageService,
    private personalisationApi: PersonalisationAPIService
  ) {
    this.segmentService
      .getCurrentSegmentId$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentSegmentId: SegmentId) => {
        this.currentSegment = currentSegmentId;
      });

    // replaced with observable - this.currentSegment = this.segmentService.getCurrentSegmentId();
    this.insightAudiences = this.segmentService.getCurrentSegment()?.insightAudiences;

    this.personalisationApi
      .getParentFirmGlobalID$()
      ?.pipe(takeUntil(this.unsubscribe$))
      ?.subscribe(
        (parentFirmId: GlobalId) => (this.parentGlobalFirmId = parentFirmId)
      );

    this.profileService
      .isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn: boolean) => {
        this.setIsLoggedIn(isLoggedIn);
        // UDS-297 Checking if ft-identity-token cookie is set to allow search for restricted documents.
        this.storageService.setCheckCookie(true);
        this.storageService
          .retrieve(FT_IDENTITY_TOKEN_KEY, false, FT_IDENTITY_TOKEN_KEY)
          .then((identityToken: string): void => {
            if (!!identityToken) {
              // Subscribe only when identityToken is set
              this.segmentService
                .getCurrentSegmentId$()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((segmentId: SegmentId) => {
                  this.setIsLoggedIn(
                    segmentId === SegmentId.FINANCIAL_PROFESSIONALS
                  );
                });
            }
          });
        this.storageService.setCheckCookie(false);
      });

    this.searchParameters = this.pageConfigService.getSearchParameters();
  }

  /**
   * returns if user is loggedIn. After login we reload the page so assumption is that this service is recreated
   */
  getIsLoggedIn(): boolean {
    return this.isLoggedIn === 'y';
  }

  /**
   * Set Is Logged In
   * @param isLoggedIn - boolean
   */
  private setIsLoggedIn(isLoggedIn: boolean): void {
    const isLoggedInStr = isLoggedIn ? 'y' : 'n';
    this.isLoggedIn = isLoggedInStr;
    this.isLoggedIn$.next(isLoggedInStr);
  }

  /**
   * this method sets default values - it can be overwritten by config values
   * @param env env string
   */
  getHttpParams(env: string) {
    return new HttpParams()
      .set('sort', 'relevance')
      .set('doNotSpellCheck', 'false')
      .set('selectedTab', '')
      .set('hideTabs', 'false')
      .set('locale', this.locale);
  }

  // TODO - user real config
  getConfig(dataSource?: string): ObservedValueOf<Observable<any>> {
    // TODO this should be configurable
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }),
    };

    // this is main part of config - reading search URLs
    const ftSearchUrl: string = this.appStateService.getSearchAPIUrl();
    const ftPreSearchUrl: string = this.appStateService.getPreSearchAPIUrl();
    const ftSearchStatusUrl: string = this.appStateService.getSearchStatusAPIUrl();
    const ftInsightsUrl: string = this.appStateService.getInsightsAPIUrl();
    const ftAutoCompleteUrl: string = this.appStateService.getAutoCompleteAPIUrl();

    // initialize spaLocale
    this.spaBaseUrl = this.appStateService.getSpaBaseUrl();

    let environment = 'dev'; // fallback in case no env variable defined
    // we can override environmetn only for search if set in .env file
    environment =
      this.appStateService.getSearchEnvironment() ||
      this.appStateService.getEnvironment();

    // #NGC-9203  quick fix for testing on beta.
    // in the future we can think about adding new setting 'environmentSearch' which can override 'environment
    if (environment === 'beta') {
      environment = 'prod';
    }

    // generate error in case there is no path defined
    // config is not called if search is not required for a site - see search.component.ts constructor()
    if (ftSearchStatusUrl === undefined || ftSearchStatusUrl === '') {
      logger.error(
        '[search] [config] search config - search url - ftSearchStatusUrl - not defined in env config file' +
          ' env: ' +
          environment
      );
    }

    let httpParams = this.getHttpParams(environment);
    httpParams = httpParams.set('audience', this.currentSegment);
    httpParams = httpParams.set('loggedIn', this.isLoggedIn$.getValue());
    httpParams = httpParams.set('env', environment);
    httpParams = httpParams.set(
      'exclude',
      this.insightAudiences ? this.insightAudiences : ''
    );

    httpParams = this.setCountrySpecificSettings(httpParams);

    // set locale for date formatting use
    this.locale = httpParams.get('locale');

    if (dataSource === 'insights') {
      httpParams = httpParams.set('audience', '');
      httpParams = httpParams.set('collection', 'pages'); // country specific, separate for insights
    }
    // add parent global firm id in params
    if (this.parentGlobalFirmId) {
      httpParams = httpParams.set(
        'parentFirmGlobalId',
        this.parentGlobalFirmId
      );
      const firmSearchLocale = this.setCustomLocaleForFirm(
        this.parentGlobalFirmId
      );
      if (firmSearchLocale) {
        httpParams = httpParams.set('locale', firmSearchLocale);
      }
    }

    return of({
      ftSearchStatusUrl,
      ftInsightsUrl,
      ftSearchUrl,
      ftPreSearchUrl,
      ftAutoCompleteUrl,
      httpParams,
      httpOptions,
    });
  }

  /**
   * set custom locale for firm
   * @param parentGlobalFirmId - parent global firm id
   * @returns - locale string
   */
  private setCustomLocaleForFirm(parentGlobalFirmId): string | null {
    return this.firmLocaleSearchConfig[parentGlobalFirmId] || null;
  }

  /**
   * overwrite search settings if defined
   */
  setCountrySpecificSettings(httpParams: HttpParams): HttpParams {
    Object.keys(this.searchParameters).forEach((key) => {
      httpParams = httpParams.set(key, this.searchParameters[key]); // country specific, separate for insights
    });
    return httpParams;
  }

  /**
   *
   */
  getResourceApiUrl() {
    return this.appStateService.getBaseResourceApiUrl();
  }

  public getRelateArticleUrl(): string {
    return this.appStateService.getRelatedArticleUrl();
  }

  /**
   * returns icon path for document type
   * @param docType literature type
   */
  getIconPath(docType: string): string {
    const iconPath = 'assets/icons/';
    let iconFile = 'ft-global-icon-search-document.svg';

    switch (docType) {
      case 'Insights':
        iconFile = 'ft-global-icon-insight.svg';
        break;
      case 'insight':
        iconFile = 'ft-global-icon-insight.svg';
        break;
      case 'Press Release':
        iconFile = 'ft-global-icon-press-release.svg';
        break;
      case 'press-release':
        iconFile = 'ft-global-icon-press-release.svg';
        break;
      case 'pdf':
        iconFile = 'ft-global-icon-pdf.svg';
        break;
      case 'csv':
        iconFile = 'ft-global-icon-csv.svg';
        break;
      case 'doc':
        iconFile = 'ft-global-icon-doc.svg';
        break;
      case 'docx':
        iconFile = 'ft-global-icon-doc.svg';
        break;
      case 'ppt':
        iconFile = 'ft-global-icon-ppt.svg';
        break;
      case 'pptx':
        iconFile = 'ft-global-icon-pptx.svg';
        break;
      case 'xls':
        iconFile = 'ft-global-icon-xls.svg';
        break;
      case 'xlsx':
        iconFile = 'ft-global-icon-xls.svg';
        break;
    }
    return iconPath + iconFile;
  }

  /**
   *
   * @param localPath path withou country prefix, adding / if needed
   */
  getLocalizedPath(localPath: string): string {
    const localizedPath =
      this.spaBaseUrl +
      (localPath.startsWith('/') ? localPath : '/' + localPath);

    return localizedPath;
  }
  /**
   * returns locale
   */
  getLocale(): string {
    return this.locale;
  }

  /**
   * returns configured order of productType
   */
  productTypeSorter(a: FilterItem, b: FilterItem): number {
    // TODO read this from config when deployed to Bloomreach
    const productsOrder = [
      'Mutual Funds',
      'ETFs',
      'Exchange Traded Funds',
      'Closed-End Funds',
      'Interval Funds',
      'Seperately Managed Account',
      '529 Portfolios',
      'Money Market Funds',
      'Variable Investment Options',
      'Institutional Mutual Funds',
      'Managed Accounts',
      'TIF',
      'TOF',
      'CIT',
    ];

    return productsOrder.indexOf(a.label) - productsOrder.indexOf(b.label);
  }

  /**
   * returns shareclasses supressed from fund title
   */
  getSuppressedShareclasses(): ShareClassCode[] {
    return [
      'SINGLCLASS' as ShareClassCode,
      'NA' as ShareClassCode,
      'N/A' as ShareClassCode,
    ];
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
